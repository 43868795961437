'use client';
import MainLayout from '@/components/layout/MainLayout';

// Error boundaries must be Client Components

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
    return (
        <MainLayout>
            <h2>Something went wrong!</h2>
            <button onClick={() => reset()}>Try again</button>
        </MainLayout>
    );
}
